var App = (function(url, userOptions) {
	var options = {};

	$.extend(options, {
		debug		: false,
		console		: false,
		reconnect	: true
	}, userOptions);

	var pusher = new Pcs.Pusher(url, options);

	return {
		getPusher : function() {
			return pusher;
		},
		run : function() {
			pusher.run(function() { 
				var selectors = {
					bid_current                 : '.offer-price-current',
					last_bid_user_id			: '.offer-progress-info .value, .bid-offer .value',
					moment						: '.bid-date .value',
					bid_next					: '.offer-bid-next .value',
					bid_increment				: '.offer-bid-raise .value'
				};

				$(document).ajaxComplete(function(e, xhr, ajaxOptions, data) {
					var $auctions = $('[data-auction], [id^="auction-"]', data), ids = [];

					if (typeof data == 'object' && data.offers) {
						$auctions = $(data.offers);
					}

					$auctions.each(function() {
						if (!$(this).data('ws-registered')) {
							var id = $(this).data('auction');

							if (ids.indexOf(id) == -1 && id)
								ids.push(id);

							$(this).data('ws-registered', true);
						}
					});

					$.each(selectors, function(i) {
						$(selectors[i]).addClass('highlight');
					});

					if (ids.length) {
						pusher.log('Ids registrados no websocket "'+ids.join(',')+'"');
						pusher.auction().register(ids);
					}
				});

				$(function() {
					var ids = [];
					var $auctions = $('[data-auction], [id^="auction-"]');

					$auctions.each(function() {
						if (!$(this).data('ws-registered')) {
							var id = $(this).data('auction');
							if (ids.indexOf(id) == -1)
								ids.push(id);
							
							$(this).data('ws-registered', true);
						}
					});

					$.each(selectors, function(i) {
						$(selectors[i]).addClass('highlight');
					});

					pusher.log('Ids registrados no websocket "'+ids.join(',')+'"');
					pusher.auction().register(ids);
                                    
				});

				pusher.auction().add('close', function(json) {
					$('[id^="auction-' + json.id+'"], [data-auction="' + json.id+'"]').each(function() {
						var $holder = $(this);
						if ($holder.data('auction-socketd')) {
							return true;
						}

						var url = $('head > base').attr('href');

						if ($holder.closest('#modal-wrapper').size()) {
							$.get(url + '/offer/offerInfo/' + json.id, function(html) {
								$('#modal-wrapper .modal-content').empty().append(html);
							});
							return true;
						}

						if ($holder.closest('#offer-info').size()) {
							$.get('', function(html) {
								$('#content').replaceWith(html);
							});
							return true;
						}

						var $tmpl = $.templates('#tmpl-offer-'+json.state),
							classes = $holder.get(0).classList,
							columns = [];
						$.each(classes, function(i, v) { if (v.indexOf('col') > -1) { columns.push(v) } });

						json.columns = columns.join(' ');
						var tmpl = $tmpl.render(json);
						if ($(tmpl).find('.offer-ended').size()) {
							$holder.replaceWith(tmpl);
						} else {
							$holder.remove();
						}

						$holder.data('auction-socketd', true);
					});
				});

				pusher.add('closed', function() {
					var $auctions = $('[data-auction], [id^="auction-"]');
					$auctions.data('ws-registered', false);
				});

				pusher.auction().add('update', function(json) {
					var $holders = $('[data-auction="' + json.id+'"]');

					$holders.each(function() {
						var $holder = $(this);
						if (!$holder.hasClass('offer-state-active') && $holder.closest('.modal-content').size() == 0 && $holder.closest('#offer-info').size() == 0) {
							var $tmpl = $.templates('#tmpl-offer-active');
							var classes = $holder.get(0).classList;
							var columns = [];
							$.each(classes, function(i, v) { if (v.indexOf('col') > -1) { columns.push(v) } });

							json.columns = columns.join(' ');

							if (json.offer == undefined) {
								json.offer = {
									id : json.id,
									overcome : false,
									lightning : $holder.find('.icon-lightning').size() > 0,
									name : $holder.find('.offer-name h3').first().text(),
									imageURL : $holder.find('.offer-image img').attr('src'),
									currentPrice : json.bid_current,
									bids : [{ user : { id : json.last_bid_user_id } }],
									endDate : json.moment,
									endDateInSeconds : json.countdown_time
								};
								json.url = {
									info : $holder.find('.offer-image a').attr('href').replace('offerDetails', 'offerInfo'),
									offer : $holder.find('.offer-image a').attr('href'),
								};
							}

							var tmpl = $tmpl.render(json);
							try {
								$holder.replaceWith(tmpl);

								if (Megaleilao && Megaleilao.startCountdown) {
									Megaleilao.startCountdown();
								}
							} catch(e) { }
						}

						if (!$holder.find('.offer-progress-info .value').size()) {
							$holder.find('.offer-progress-info').empty().append(
								'<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 title">Ofertado por:</div>' +
								'<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 value highlight"></div>'
							);
						}

						if (!$holder.find('.bid-date .value').size()) {
							$holder.find('.bid-offer').before(
							'<div class="bid-date row">' +
							'	<div class="col-xs-5 col-sm-6 col-md-6 col-lg-6 title">' +
							'		Data do Lance:' +
							'		</div>' +
							'	<div class="col-xs-7 col-sm-6 col-md-6 col-lg-6 value">' +
							'		<span class="text-date">-</span>' +
							'	</div>' +
							'</div>');
						}

						if (!$holder.find('.bid-offer .value').size()) {
							$holder.find('.bid-offer').empty().append(
								'<div class="col-xs-5 col-sm-6 col-md-6 col-lg-6 title">Ofertado por:</div>' +
								'<div class="col-xs-7 col-sm-6 col-md-6 col-lg-6 value highlight"></div>'
							);
						}

						$.each(selectors, function(i) {
							if (json[i]) {
								$(selectors[i], $holder).html(json[i]);
								$(selectors[i], $holder).addClass('highlight-over');
							}
						});

						setTimeout(function() {
							$.each(selectors, function(i) {
								$(selectors[i], $holder).removeClass('highlight-over');
							});
						}, 2000);

						if ($holder.closest('.dispute-offers').size() && $('.dispute-offers [data-auction="'+json.id+'"]').size()) {
							var $offerHolder = $('[data-auction="'+json.id+'"]');

							if (!Megaleilao.WebNotification) {
								Megaleilao.WebNotification.create('Lance em Leilão em Disputa', 'Foi dado lance no leilão #'+json.id+' no valor de '+json.bid_current+' por ' + json.offer.bids[0].user.id + '.', function() {
									$offerHolder.find('.btn-bid').click();
								});
							}

							if (json.loggedUser != json.offer.bids[0].user.id) {
								$('.offer-status', $offerHolder).addClass('auction-overcome').find('.offer-image img').after('<span class="icon icon-overcome"></span>');
								// if (!$offerHolder.data('web-notify') && Megaleilao.WebNotification) {
								// 	Megaleilao.WebNotification.create('Leilão Superado', 'Lance superado no leilão #'+json.id+' no valor de '+json.bid_current+' por ' + json.offer.bids[0].user.id + '.', function() {
								// 		$offerHolder.find('.btn-bid').click();
								// 	});
								// }
								// $offerHolder.data('web-notify', true);
							} else {
								$('.offer-status', $offerHolder).removeClass('auction-overcome').find('.offer-image span.icon-overcome').remove();
							}
						}

						if ($holder.closest('.modal-content').size() > 0 || $holder.closest('#offer-info').size() > 0) { 
							var $history = $('[data-auction-history="'+json.id+'"]');
							var $history_table = $('table', $history);
							if (!$history_table.size()) {
								if (Megaleilao.mobile) {
									$('.offer-bid-history-quantity', $history).hide();
									$history = $history.find('.megaleilao-accordion-content');
								}

								$history.append(
									$history_table = $(
										'<table class="tablesorter"><thead><tr>' +
										'<th scope="col">Comprador</th>' +
										'<th scope="col">Lance</th>' +
										'</tr></thead><tbody></tbody></table>'
									).toggle()
								);
							}

							if ($history.size()) {
								var $tbody = $history_table.find('tbody');
								var tr = '<tr>' +
								'<td><div class="user">'+json.last_bid_user_id+'</div><div class="address">'+json.last_bid_user_city+' / '+json.last_bid_user_state+'</div><div class="bid-date">'+json.moment+'</div></td>' +
								'<td>'+json.bid_current+'</td>' +
								'</tr>';

								var num = $tbody.find('tr').size();

								$(tr).prependTo($tbody);

								$('strong', '[data-auction-history] .offer-bid-history-quantity').html('Quantidade de lances: <span class="value">0</span>');

								if (!$('[data-auction-history] .megaleilao-accordion-header .value.badge').size()) {
		 							$('[data-auction-history] .megaleilao-accordion-header').append('<span class="value badge pull-right">0</span>');
								}

								$('.offer-bid-history-quantity .value, [data-auction-history] .megaleilao-accordion-header .value.badge').html(num+1);
							}
						}
					});
				});
			});
		}
	};
});
