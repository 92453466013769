var Pcs = {};
Pcs.Pusher = (function(url, options) {

	function merge(obj, obj2) {
		for(var opt in obj2) {
			if (obj[opt] === undefined) {
				obj[opt] = obj2[opt];
			}

			if (typeof obj[opt] === 'object') {
				obj[opt] = merge(obj[opt], obj2[opt]);
			}
		}
		return obj;
	}

	var defaults = {
		debug			: true,
		reconnect		: true
	};

	var pvt = {
		websocket		: null,
		connected		: false,
		callback		: {
			connected	: new Array(),
			closed		: new Array()
		}
	};

	var self = this;

	var Pusher = function() { };
	Pusher.prototype.log = function(message) {
		if (options.debug) {
			if (options.console) {
				var $message = $('#messages');
				if (!$message.size()) {
					$message = $('<div id="messages" style="position:absolute;top:0;left:0;width:400px;height:400px;overflow:auto;border:1px solid;background:#fff;" />');
					$('body').append($message);
				}
				$message.append('<p>'+message+'</p>');
			}
			console.warn(message);
		}
	};

	Pusher.prototype.isConnected = function() {
		return pvt.connected;
	};

	Pusher.prototype.createSocket = function() {
		var self = this;

		pvt.websocket = null;
		delete pvt.websocket;

		window.WEB_SOCKET_SWF_LOCATION = "websocket/public/js/WebSocketMain.swf";

		pvt.websocket = new ab.Session(url, function() {
			pvt.connected = true;
			setTimeout(function() {
				self.log('SERVER => [connection] Connected!');
				self.executeEvent(pvt.callback.connected);
			}, 1000);
		},
		function() {
			if (pvt.connected) {
				self.log('SERVER => [connection] Disconnected!');
			}
			self.executeEvent(pvt.callback.closed);
			pvt.connected = false;
		},
		{
			'skipSubprotocolCheck': true
		}
		);
		return this.getSocket();
	};

	Pusher.prototype.send = function(msg) {
		if (!this.isConnected()) {
			return false;
		}

		switch(true) {
			case typeof msg.toJSON == 'function' :
				msg = msg.toJSON();
				break;
			default :
				msg = JSON.stringify(msg);
				break;
		}

		return pvt.websocket.send(msg);
	};

	Pusher.prototype.getSocket = function() {
		return pvt.websocket;
	};

	Pusher.prototype.disconnect = function() {
		this.getSocket().close();
	};

	Pusher.prototype.executeEvent = function(callbacks, params) {
		if (!(params instanceof Array)) {
			params = [];
		}
		callbacks.forEach(function(index, callback) {
			if (typeof index === 'function') {
				index.apply(pvt.websocket, params);
			}
		});
	};

	Pusher.prototype.add = function(eventName, callback) {
		pvt.callback[eventName].push(callback);
	};

	Pusher.prototype.subscribe = function(eventName, callback) {
		return this.getSocket().subscribe(eventName, callback);
	};

	Pusher.prototype.run = function(callback) {
		var self = this,
		flagMessage = false;

		options = merge(options, defaults);

		if (options.debug) {
			ab.debug(true, false, false);
		}

		self.createSocket();

		this.add('closed', function() {
			if (options.reconnect) {
				if (!flagMessage) {
					self.log('SERVER => [connection] Ops! We shouldn\'t be disconnected... retrying...');
					flagMessage = true;
				}
				setTimeout(function() {
					self.createSocket();
				}, 2500);
			}
		});

		this.add('connected', function() {
			if (flagMessage) {
				flagMessage = false;
			}

			this.subscribe('com.megaleilao.protocol', function(topic, message) {
				switch(message) {
					case 'PING' :
						self.log('SERVER => ['+ topic +'] PONG');
						self.getSocket().call('com.megaleilao.protocol', 'PONG');
					break;
				}
				self.log('SERVER => ['+ topic +'] ' + message);
			});

			callback();
		});
	};

	Pusher.prototype.auction = function() {
		var self = this,
			auction = {
				add : function(eventName, callback) {
					if (!pvt.callback.auction) {
						pvt.callback.auction = { };
					}
					if (!pvt.callback.auction[eventName]) {
						pvt.callback.auction[eventName] = [];
					}
					pvt.callback.auction[eventName].push(callback);
				},
				register : function(ids) {
					self.getSocket().call('com.megaleilao.auction.register', {
						auctions : ids
					}).then(
						function (result) { },
						function (error) {
							self.log(error);
						}
					);
				},
				unregister : function(ids) {
					self.getSocket().call('com.megaleilao.auction.unregister', {
						auctions : ids
					}).then(
						function (result) { },
						function (error) {
							self.log(error);
						}
					);
				}
			},
			addEventUpdate = function() {
                            self.subscribe('com.megaleilao.auction.update', function(topic, message) {
                                self.log('SERVER => ['+ topic +'] Offer #' + message['id'] + ' update.');
                                    if (pvt.callback.auction && pvt.callback.auction['update']) {
                                        self.executeEvent(pvt.callback.auction['update'], [message]);
                                    }
                            });
                            self.subscribe('com.megaleilao.auction.close', function(topic, message) {
                                self.log('SERVER => ['+ topic +'] Offer #' + message['id'] + ' close.');
                                    if (pvt.callback.auction && pvt.callback.auction['close']) {
                                        self.executeEvent(pvt.callback.auction['close'], [message]);
                                    }
                            });
			};

		if (!self.auctionLoaded) {
			self.add('connected', function() {
				addEventUpdate();
			});
			addEventUpdate();
			self.auctionLoaded = true;
		}

		return auction;
	};

	return new Pusher();
});
